.App {
  /* text-align: center; */
  /* background-color: #282c34; */
  display: flex;
  min-height: 100vh;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column; */
  /* align-items: center;
  justify-content: center; */
  /* font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.input {
  width: 260px;
  text-align: center;
  margin: 20px 20px 0 20px;
}

.artists {
  display: flex;
  flex-direction: row;
}

.card {
  margin: 10px;
  padding: 10px;
  max-width: 500px;
  display: flex;
  flex-direction: row;
  min-width: 350px;
}

.text {
  font-family: 'Source Sans Pro';
  font-size: large;
}

.text-bold {
  font-family: 'Source Sans Pro';
  font-size: large;
  font-weight: 600;
}

.album-photo {
  padding: 10px;
}

.split {
  padding: 10px;
  flex-direction: column;
}

.button {
  width: 260px;
  margin: 20px 20px 10px 20px;
}

.input-view {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('./fonts/source-sans-pro-v18-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/source-sans-pro-v18-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-600 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: local(''),
       url('./fonts/source-sans-pro-v18-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/source-sans-pro-v18-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
